<template>
	<!-- 添加编辑土地订单服务信息 -->
	<div class="land-work-service el-content">
		<a-form :label-col="{span:2}" :wrapper-col="{span:12}">
			<a-form-item label="操作类型名称" required>
				<a-input v-model:value="form.title" placeholder="请输入操作类型名称"></a-input>
			</a-form-item>
			<a-form-item label="操作类型描述" required>
				<a-input v-model:value="form.summary" placeholder="请输入操作类型描述,15字以内"></a-input>
			</a-form-item>
			<a-form-item label="封面" required>
				<kd-img-select :src="form.images" @change="(url)=>{ form.images = url }"></kd-img-select>
			</a-form-item>
			<a-form-item label="是否特殊工单">
				<a-radio-group v-model:value="form.is_special">
				    <a-radio :value="2">是</a-radio>
				    <a-radio :value="1">否</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="特殊工单类型" v-if="form.is_special == 2">
				<a-radio-group v-model:value="form.special_type">
				    <a-radio :value="1">土地装饰</a-radio>
				    <a-radio :value="2">作物采摘</a-radio>
				    <a-radio :value="3">浇水</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="显示状态">
				<a-radio-group v-model:value="form.status">
				    <a-radio :value="1">显示</a-radio>
				    <a-radio :value="2">不显示</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="添加操作项">
				<div class="kws-item" v-for="(item,index) in form.workorderdetails" :key="index">
					<div class="kws-item-title">
						<div>操作项1</div>
						<div @click="operationItem(index)">删除</div>
					</div>
					<div class="kws-item-input">
						<div class="kws-item-input-title">操作名称</div>
						<a-input placeholder="请输入操作名称" style="width: 70%;" v-model:value="item.title"></a-input>
					</div>
					<div class="kws-item-input">
						<div class="kws-item-input-title">操作费用</div>
						 <a-input v-model:value="item.price">
							<template #addonAfter>
								<a-select v-model:value="item.company">
									<a-select-option value="">请选择</a-select-option>
									<!-- 仅浇水可用 -->
									<template v-if="form.special_type == 3 && form.is_special == 2">
										<a-select-option value="6">元/s</a-select-option>
									</template>
									
									<template v-else>
										<a-select-option value="1">元/m²</a-select-option>
										<a-select-option value="3">元/次</a-select-option>
										<template v-if="isShop == 0">
											<a-select-option value="2">积分/m²</a-select-option>
											<a-select-option value="4">积分/次</a-select-option>
										</template>
										<!-- 仅采摘可用 -->
										<template v-if="form.special_type == 2 && form.is_special == 2">
											<a-select-option value="5">元/kg</a-select-option>
										</template>
									</template>
								</a-select>
							</template>
						</a-input>
					</div>
				</div>
				<div class="kws-btn" @click="operationItem(-1)">
					<i class="ri-add-line"></i>添加操作项
				</div>
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 2 }">
				<a-space>
					<a-button type="primary" @click="submitSave">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import landModel from '@/api/land.js'
export default{
	name:"page-add-work-service",
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			form:{
				id:0,
				title:"",
				summary:"",
				images:"",
				status:0,
				is_pick:1,
				is_decoration:1,
				is_special:1,
				special_type:1,
				workorderdetails:[{
						title:'',
						price:'',
						company:'',		//  元/m²:1、农币/m²:2、元/次:3、农币/次:4 
					}
				]
			},
		})

		const opt = useRoute().query
		if( opt.id ) landModel.getLandWorkServerDetail(opt.id,Object.keys(_d.form),res=>_d.form = res)

		function operationItem(index){
			if( index == -1 ){
				_d.form.workorderdetails.push({
					title:'',
					price:'',
					company:'',
					bind_action:0
				})
				return
			}
			let id =_d.form.workorderdetails[index].id
			if( !id){
				_d.form.workorderdetails.splice(index,1)
				return
			}
			landModel.deleleLandWorkServerItem(id,()=> _d.form.workorderdetails.splice(index,1) )
		}
		const submitSave = () => landModel.addOrEditLandWorkServer(_d.form)

		return{
			...toRefs(_d),
			operationItem,
			submitSave
		}
	},
}
</script>

<style lang="scss">
	.land-work-service{
		.kws-item{
			width: 500px;
			border: 1px solid #f4f4f4;
			padding: 0 12px;
			box-sizing: border-box;
			margin-bottom: 12px;
			
			&-title{
				display: flex;
				height: 50px;
				line-height: 50px;
				justify-content: space-between;
				cursor: pointer;
			}
			
			&-input{
				margin-bottom: 12px;
				display: flex;
				align-items: center;
				&-title{
					width: 80px;
					color: #666;
				}
			}
		}
		.kws-btn{
			width: 500px;
			text-align: center;
			height: 40px;
			line-height: 40px;
			border: 1px dashed #d8d8d8;
			margin-top: 16px;
			cursor: pointer;
			color: #999;
		}
	}
	
</style>
